import styled from '@emotion/styled';
import GeneratedIdWrapper from './GeneratedIdWrapper';
import Button, { BUTTON_TYPES } from 'components/Button';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import { IColor } from 'types';
import {
  getThemeFontFamily,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  getThemeInputWidthShape,
  getThemeGutter,
  getThemeFontLineHeight,
  getThemeFontSize,
  ITheme,
  getThemeBackgroundColors,
  FONT_FAMILY,
  getThemeAvatarShape,
  getThemeNeutralColors,
  getThemeButtonOperationalColor,
  getThemeInputBorderShape,
  BORDER_PROPS,
  getThemeNeutralColor,
  COLOR_SHADE,
  getThemeBackgroundColor,
  getThemeInputPaddingShape,
} from 'theme';
import { flexColContainerStyles } from 'commonStyles';
import { Box } from 'components/v2/Box';
import { MAX_BODY_CONTENT_WIDTH } from 'constants/styles';
import { themev2 } from 'theme-v2';

export const BackgroundHeroWrapper = styled.div<IColor & ITheme>(
  ({ background, ...props }) => ({
    background,
    margin: `${getThemeGutter(props, TSHIRT_SIZE.XL)} auto`,
    padding: '0 20px',
    width: '100%',
    display: CSS_DISPLAY.FLEX,
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    alignItems: CSS_ALIGN.CENTER,
  })
);

export const BlogHeroWrapper = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  maxWidth: '1024px',
  margin: '0 auto',
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
}));

export const BlogHeroInfo = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.GRID,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  alignItems: CSS_ALIGN.FLEX_START,
  gridAutoColumns: '1fr',
  gap: '16px',
  gridTemplateColumns: '1fr 1fr',
  gridTemplateRows: 'auto',
  height: 'auto',
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    gridTemplateColumns: '1fr',
    columnGap: '16px',
    rowGap: '48px',
    gridTemplateRows: 'auto auto',
  },
}));

export const DateComponent = styled.div<ITheme>((props) => ({
  gridColumnStart: 1,
  gridColumnEnd: 2,
  gridRowStart: 1,
  gridRowEnd: 2,
  height: '30px',
  paddingTop: '5px',
  float: 'left',
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  fontWeight: 600,
  color: getThemeBackgroundColors(props).deep,
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    justifySelf: CSS_JUSTIFY_CONTENT.CENTER,
  },
}));

export const TimeComponent = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  justifyContent: CSS_JUSTIFY_CONTENT.FLEX_END,
  height: '30px',
  paddingTop: '5px',
  float: 'left',
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  svg: {
    marginRight: getThemeGutter(props, TSHIRT_SIZE.XS),
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    justifySelf: CSS_JUSTIFY_CONTENT.CENTER,
  },
}));

export const Title = styled.div<ITheme>((props) => ({
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
  marginTop: getThemeGutter(props, TSHIRT_SIZE.XXS),
}));

export const BlogHeroImageContainer = styled.div<ITheme>({
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  maxWidth: '1024px',
  maxHeight: '576px',
});

export const BlogImageContainer = styled.div(() => ({
  ...flexColContainerStyles,
  justifyContent: 'flex-end',
  div: {
    objectFit: 'fill',
    maxWidth: '1120px',
    img: {
      display: 'inline',
      borderRadius: '12px',
    },
  },
}));

export const BlogHeroDescription = styled(Box)({
  'p, ul, ol, strong, em, a': {
    fontSize: themev2.fonts.size.h4,
    fontFamily: themev2.fonts.family.additional,
  },
  marginBottom: '27px',
  '>p+p': {
    marginTop: '27px',
  },
});

export const BlogAuthorContainer = styled.div<ITheme>(() => ({
  display: CSS_DISPLAY.GRID,
  width: '100vw',
  padding: `0 20px`,
}));

export const BlogAuthorWrapper = styled.div<ITheme>((props) => ({
  maxWidth: '1024px',
  display: CSS_DISPLAY.GRID,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  margin: `0 auto ${getThemeGutter(props, TSHIRT_SIZE.XL)}`,
  padding: `${getThemeGutter(props, TSHIRT_SIZE.L)} 0 0`,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  gridAutoColumns: '1fr',
  gap: '16px',
  gridTemplateColumns: '95px 1fr',
  gridTemplateRows: 'auto',
  borderStyle: 'solid',
  borderWidth: '1px 0 0 ',
  borderColor: 'rgba(18,33,48,.1)',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    gridTemplateColumns: '20% 1fr',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto auto',
  },
}));

export const BlogAuthorImageContainer = styled.div<ITheme>((props) => ({
  width: '95px',
  height: '95px',
  div: {
    width: '100%',
    height: '100%',
    img: {
      objectFit: 'cover !important',
    },
  },
  img: {
    borderRadius: getThemeAvatarShape(props).borderRadius,
    verticalAlign: 'middle',
    display: CSS_DISPLAY.INLINE_BLOCK,
    maxWidth: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    justifySelf: CSS_JUSTIFY_CONTENT.CENTER,
  },
}));

export const BlogAuthorInfoContainer = styled.div<ITheme>((props) => ({
  height: 'auto',
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
}));

export const BlogAuthorName = styled.div<ITheme>((props) => ({
  p: {
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
    fontWeight: 600,
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    },
  },
}));

export const BlogAuthorDescription = styled.div<ITheme>((props) => ({
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  p: {
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    fontWeight: 400,
  },
}));

export const BlogAuthorText = styled.div<ITheme>((props) => ({
  p: {
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    color: getThemeButtonOperationalColor(props, BUTTON_TYPES.PRIMARY).text
      .main,
    fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
    [MEDIA_QUERIES.TABLET]: {
      marginBottom: '15px',
    },
  },
}));

export const StyledCodeSection = styled.section<ITheme>((props) => ({
  width: '100vw',
  padding: '0 20px',
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
}));

export const StyledCodeBlock = styled.div<ITheme>((props) => ({
  maxWidth: '1024px',
  width: '100%',
  maxHeight: '167px',
  overflow: 'scroll',
  margin: `0 auto ${getThemeGutter(props, TSHIRT_SIZE.S)}`,
  padding: getThemeGutter(props, TSHIRT_SIZE.S),
  borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
  background: getThemeNeutralColor(props, COLOR_SHADE.DEEP),
  color: getThemeNeutralColor(props, COLOR_SHADE.LIGHT),
  pre: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P2),
  },
}));

export const StyledCopyButton = styled(Button)<ITheme>((props) => ({
  margin: 0,
  maxHeight: '43px',
  padding: `${getThemeInputPaddingShape(
    props,
    TSHIRT_SIZE.S
  )} 15px ${getThemeInputPaddingShape(props, TSHIRT_SIZE.M)}`,

  ':hover': {
    padding: `9px 14px ${getThemeInputPaddingShape(props, TSHIRT_SIZE.S)}`,
  },
  [MEDIA_QUERIES.TABLET]: {
    width: 'auto',
  },
}));

export const CopyButtonContainer = styled.div({
  maxWidth: '1024px',
  width: '100%',
  margin: '0 auto',
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  },
});

export const CopyMessageContainer = styled.div<ITheme>((props) => ({
  marginLeft: getThemeGutter(props, TSHIRT_SIZE.S),
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    marginLeft: 0,
    marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
  },
}));

export const CopyMessage = styled.span<ITheme>((props) => ({
  marginLeft: getThemeGutter(props, TSHIRT_SIZE.XS),
  color: getThemeBackgroundColor(props, COLOR_SHADE.DEEP),
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
}));

export const ImgTextBlockContainer = styled(GeneratedIdWrapper)<ITheme>(
  (props) => ({
    width: '100vw',
    padding: '0 20px',
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
    'h1, h2, h3, h4, h5, h6': {
      width: '100%',
      maxWidth: '1024px',
      margin: `0 auto ${getThemeGutter(props, TSHIRT_SIZE.S)}`,
      color: getThemeNeutralColors(props).deep,
    },
  })
);

export const ImgTextBlock = styled.div<ITheme & { isOnTheRight: boolean }>(
  ({ isOnTheRight }) => ({
    width: '100%',
    maxWidth: '1024px',
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: isOnTheRight ? '1fr 30%' : '30% 1fr',
    gridTemplateRows: 'auto',
    columnGap: '48px',
    alignItems: CSS_ALIGN.BASELINE,
    'p, ul, ol, strong, em, a': {
      fontSize: themev2.fonts.size.h4,
      fontFamily: themev2.fonts.family.additional,
    },
    [MEDIA_QUERIES.TABLET]: {
      gridTemplateColumns: '100%',
      gridTemplateRows: 'repeat(2,auto)',
      rowGap: '48px',
    },
  })
);

export const ImgTextBlockTitleWrapper = styled.div({
  maxWidth: MAX_BODY_CONTENT_WIDTH,
  margin: '0 auto',
});

export const SideImageText = styled.div<ITheme>({
  'h1, h2, h3, h4, h5, h6': {
    textAlign: TEXT_ALIGN.LEFT,
  },
  '*+*': {
    marginTop: '12px',
  },
  'p+p': {
    marginTop: '27px',
  },
  '*+h3': {
    marginTop: '27px',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    'p, strong, em, a, h5, li': {
      fontSize: themev2.fonts.size.p_medium,
    },
    h2: {
      fontSize: themev2.fonts.size.h3,
    },
    h3: {
      fontSize: themev2.fonts.size.h4,
    },
    h4: {
      fontSize: themev2.fonts.size.h5,
    },
    h6: {
      fontSize: themev2.fonts.size.p_small,
    },
  },
});

export const SideImageContainer = styled.div<
  ITheme & { isOnTheRight: boolean }
>(({ isOnTheRight }) => ({
  order: isOnTheRight ? 1 : -1,
  div: {
    div: {
      maxWidth: 'unset !important',
    },
  },
  img: {
    objectFit: 'cover !important',
    width: 'auto',
    height: '310px',
  },
  [MEDIA_QUERIES.TABLET]: {
    order: -1,
    div: {
      width: '100%',
      img: {
        width: '100%',
        height: 'fit-content',
      },
    },
  },
}));

export const ColumnsTextContainer = styled.div<ITheme>(({}) => ({
  width: '100vw',
  padding: '0 20px',
}));

export const ColumnsTextWrapper = styled.div<ITheme & { columns: number }>(
  ({ columns, ...props }) => ({
    width: '100%',
    maxWidth: '1024px',
    margin: `0 auto ${getThemeGutter(props, TSHIRT_SIZE.XL)}`,
    display: CSS_DISPLAY.GRID,
    gridTemplateColumns: `repeat(${columns || 3}, 1fr)`,
    gap: getThemeGutter(props, TSHIRT_SIZE.L),
    [MEDIA_QUERIES.TABLET]: {
      columnGap: getThemeGutter(props, TSHIRT_SIZE.S),
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      gridTemplateColumns: '1fr',
    },
  })
);
